import React, { memo, useEffect } from 'react';
import LibDatePicker, {
  getDefaultLocale,
  registerLocale,
  setDefaultLocale,
} from 'react-datepicker';
import { DEFAULT_LOCALE } from 'constants/constants';

const DatePicker = (props) => {
  useEffect(() => {
    const getLocale = async () => {
      if (!getDefaultLocale()) {
        let locale =
          window.navigator.userLanguage ||
          window.navigator.language ||
          DEFAULT_LOCALE;

        let mod;

        try {
          mod = await import(`date-fns/locale/${locale}/index.js`);
        } catch (error) {
          mod = await import('date-fns/locale/en-US/index.js');
          locale = DEFAULT_LOCALE;
        }

        registerLocale(locale, mod.default);
        setDefaultLocale(locale);
      }
    };

    getLocale();
  }, []);

  return <LibDatePicker {...props} />;
};

export default memo(DatePicker);
