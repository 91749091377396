import React from 'react';
import verifiedImage from './verified.png';

const Verified = ({ user, style = {}, ...rest }) => {
  const verified = user.verified;

  return verified ? (
    <img
      src={verifiedImage}
      title="Verified account"
      className="rel"
      style={Object.assign(
        { height: '15px', top: '-1px', marginRight: '2px' },
        style
      )}
      alt="Twine"
      {...rest}
    />
  ) : (
    <noscript />
  );
};

export default Verified;
