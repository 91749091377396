import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';
import Button from 'react-bootstrap/lib/Button';
import CropperJS from 'react-cropper';
import Thumbnail from 'components/Thumbnails/Thumbnail';
import styles from './ImageCropper2.module.scss';
import 'cropperjs/dist/cropper.css';

class ImageCropper extends Component {
  static propTypes = {
    aspectRatio: PropTypes.number,
    existingImage: PropTypes.object,
    existingImageCircle: PropTypes.bool,
    onDone: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    aspectRatio: 1,
    existingImage: null,
    existingImageCircle: false,
    onDone: (details) => console.log('ImageCropper', details),
  };

  constructor() {
    super();

    this.state = {
      cropData: null,
      originalImage: null,
      src: null,
    };

    this.cropperRef = createRef();
  }

  handleChangeFile = (event) => {
    event.preventDefault();

    let files;

    if (event.dataTransfer) {
      files = event.dataTransfer.files;
    } else if (event.target) {
      files = event.target.files;
    }

    const reader = new FileReader();

    reader.onload = () => {
      this.setState({
        src: reader.result,
        originalImage: files[0],
      });
    };

    reader.readAsDataURL(files[0]);
  };

  handleCrop = (event) => {
    const cropData = event.detail;

    this.setState({
      cropData,
    });
  };

  handleDone = () =>
    this.props.onDone({
      x: this.state.cropData.x < 0 ? 0 : this.state.cropData.x,
      y: this.state.cropData.y < 0 ? 0 : this.state.cropData.y,
      width: this.state.cropData.width,
      height: this.state.cropData.height,
      image: this.state.originalImage,
      croppedImage: this.cropperRef.current.cropper
        .getCroppedCanvas()
        .toDataURL(),
    });

  render() {
    const {
      aspectRatio,
      existingImage,
      existingImageCircle,
      className,
      ...rest
    } = this.props;
    const { src } = this.state;

    return (
      <div
        className={styles.container + (className ? ' ' + className : '')}
        {...rest}
      >
        <div
          className={styles.mainImage}
          style={{ paddingBottom: `${(1 / aspectRatio) * 100}%` }}
        >
          {!src && (
            <Thumbnail
              circle={existingImageCircle}
              className={styles.thumbnail}
              thumbnails={existingImage}
            />
          )}
          {src && (
            <CropperJS
              aspectRatio={aspectRatio}
              checkCrossOrigin={false}
              crop={this.handleCrop}
              guides={false}
              ref={this.cropperRef}
              src={src}
              style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
              }} // Does not respect className
              viewMode={2}
            />
          )}
        </div>
        <input
          onChange={this.handleChangeFile}
          type="file"
          id="image-cropper-upload-button"
          accept=".png,.jpeg,.jpg,"
        />
        <Button
          bsStyle="success"
          className={styles.button}
          onClick={() =>
            src
              ? this.handleDone()
              : document.getElementById('image-cropper-upload-button').click()
          }
        >
          {src ? 'Done' : 'Upload image'}
        </Button>
      </div>
    );
  }
}

export default ImageCropper;
