import React from 'react';
import Icon from 'components/Icon/Icon';

const Rating = ({
  rating = 5.0,
  style,
  className = '',
  outOf = 5,
  onClick = null,
}) => (
  <div
    className={className}
    style={{
      textAlign: 'center',
      whiteSpace: 'nowrap',
      position: 'relative',
      cursor: onClick ? 'pointer' : 'auto',
      ...style,
    }}
    onClick={onClick ? onClick : () => {}}
  >
    {[...Array(outOf)].map((dummy, idx) => {
      return (
        <div
          key={idx}
          style={{
            position: 'relative',
            display: 'inline-block',
            marginRight: '3px',
          }}
        >
          <Icon icon="&#xe602;" color="#d5d5d5" />
          {rating >= idx + 1 && (
            <Icon
              icon="&#xe602;"
              style={{ position: 'absolute', top: 1, left: 0 }}
              color="#ffd700"
            />
          )}
          {rating - idx > 0 && rating - idx < 1 && (
            <Icon
              icon="&#xe602;"
              style={{
                position: 'absolute',
                top: 1,
                left: 0,
                overflow: 'hidden',
                width: (rating - idx) * 100 + '%',
              }}
              color="#ffd700"
            />
          )}
        </div>
      );
    })}
  </div>
);
export default Rating;
