import React from 'react';
import Label from 'react-bootstrap/lib/Label';

const Pro = ({ user, ...rest }) => {
  return user.pro ? (
    <div {...rest}>
      <Label
        style={Object.assign(
          { backgroundColor: '#F2C64A', color: '#333333' },
          rest.style ? rest.style : {}
        )}
      >
        PRO
      </Label>
    </div>
  ) : (
    <noscript />
  );
};

export default Pro;
