import React from 'react';
import PropTypes from 'prop-types';
import verifiedImage from './verified.png';

const Verified = ({ user, overrideAvatar, style = {}, ...rest }) => {
  const verified =
    user.verified &&
    user.location &&
    user.location.text !== '' &&
    (overrideAvatar ||
      (user.avatars &&
        user.avatars.images[30] &&
        user.avatars.images[30].square.main.indexOf('img/placeholders') ===
          -1));

  return verified ? (
    <img
      src={verifiedImage}
      title="Verified account"
      className="rel"
      style={Object.assign(
        { height: '15px', top: '-1px', marginRight: '2px' },
        style
      )}
      alt="Twine"
      {...rest}
    />
  ) : (
    <noscript />
  );
};

Verified.propTypes = {
  user: PropTypes.object.isRequired,
  overrideAvatar: PropTypes.bool,
  style: PropTypes.object,
  ...React.Component.propTypes,
};

export default Verified;
