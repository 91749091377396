import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import PortfolioAd from './PortfolioAd';
import Panel from 'components/Panel/Panel';
import { checkUserLocation } from 'helpers/HelperFunctions';
import styles from './PortfolioAdverts.module.scss';
import { PORTFOLIO_AD_ROTATION_MILLISECONDS } from 'constants/constants.js';

import adverts from './AdvertData';

const advertAnimation = {
  classNames: 'animation-fadein-v1',
  enter: true,
  // transitionAppear: false,
  exit: false,
  timeout: { enter: 500 },
};

const getRandomChoice = (upperLimit = 0, lowerLimit = 0) => {
  return lowerLimit + Math.floor(Math.random() * upperLimit);
};

class PortfolioAdverts extends React.PureComponent {
  state = {
    advertArray: [],
    advertIndex: 0,
    advertData: {},
    hasChosenAdvert: false,
  };

  componentDidMount() {
    if (this.props.loadedPartners) {
      this.chooseAdvert();
    }

    this.timer = setInterval(() => {
      this.handleNextAdvert();
    }, PORTFOLIO_AD_ROTATION_MILLISECONDS);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  componentDidUpdate() {
    const { userLocation, loadedPartners } = this.props;
    const { hasChosenAdvert, advertData } = this.state;

    if ((!hasChosenAdvert || !advertData) && userLocation && loadedPartners) {
      this.chooseAdvert();
    }
  }

  chooseAdvert = () => {
    const { userLocation, partners } = this.props;
    const advertArray =
      partners.length > 0
        ? partners.sort(() => 0.5 - Math.random())
        : adverts.freelancerToolkit;

    const usAdverts = advertArray.filter((ad) =>
      ad.location_include?.includes('United States')
    );

    // Ensure a US specific ad is shown first
    const locationText = userLocation ? userLocation.toLowerCase() : null;

    const chooseBasedOnLocation = () => {
      let advert, advertIndex;

      if (locationText && checkUserLocation(locationText, ['united states'])) {
        if (usAdverts.length > 0) {
          advertIndex = getRandomChoice(usAdverts.length);
          advert = usAdverts[advertIndex];
        }
      }

      if (!advert) {
        advertIndex = getRandomChoice(advertArray.length);
        advert = advertArray[advertIndex];
      }

      return { advert, advertIndex };
    };

    const { advert, advertIndex } = chooseBasedOnLocation();

    this.setState({
      hasChosenAdvert: true,
      advertArray,
      advertIndex,
      advertData: advert,
    });
  };

  handleScrollAdvert = (index) =>
    this.setState({
      advertIndex: index,
      advertData: this.state.advertArray[index],
    });

  handlePrevAdvert = () => {
    const { advertIndex, advertArray } = this.state;
    const newIndex =
      advertIndex - 1 === 0 ? advertArray.length - 1 : advertIndex - 1;

    return this.handleScrollAdvert(newIndex);
  };

  handleNextAdvert = () => {
    const { advertIndex, advertArray } = this.state;

    const newIndex =
      advertIndex + 1 >= advertArray.length ? 0 : advertIndex + 1;

    return this.handleScrollAdvert(newIndex);
  };

  render() {
    const { hasChosenAdvert, advertIndex, advertData } = this.state;
    return hasChosenAdvert && advertData ? (
      <Panel
        backgroundColor={advertData.background}
        className={styles.portfolioAdPanel}
      >
        <TransitionGroup>
          <CSSTransition {...advertAnimation}>
            <div key={advertIndex}>
              <PortfolioAd
                advertData={advertData}
                onNextAdvert={this.handleNextAdvert}
                onPrevAdvert={this.handlePrevAdvert}
              />
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Panel>
    ) : (
      <span />
    );
  }
}

PortfolioAdverts.propTypes = {
  userLocation: PropTypes.string,
  partners: PropTypes.array,
  loadedPartners: PropTypes.bool,
  loadPartners: PropTypes.func,
};
PortfolioAdverts.defaultProps = {
  userLocation: null,
};

export default PortfolioAdverts;
