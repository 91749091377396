import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styles from './FormSelect.module.scss';

export default class FormSelect extends Component {
  static propTypes = {
    id: PropTypes.string,
    options: PropTypes.array,
    valueField: PropTypes.string,
    labelField: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string,
  };

  constructor(props) {
    super(props);
    const selected = this.getSelectedFromProps(props);
    this.state = { selected };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const selected = this.getSelectedFromProps(nextProps);
    this.setState({ selected });
  }

  getSelectedFromProps(props) {
    let selected;
    if (props.value === null && props.options.length !== 0) {
      selected = props.options[0][props.valueField];
    } else {
      selected = props.value;
    }
    return selected;
  }

  handleChange(event) {
    if (this.props.onChange) {
      this.props.onChange(
        isNaN(event.target.value)
          ? event.target.value
          : parseInt(event.target.value, 10)
      );
    }
    this.setState({ selected: event.target.value });
  }

  render() {
    const {
      id,
      options,
      valueField = 'value',
      labelField = 'label',
      disabled = false,
      className = '',
    } = this.props;
    const selectOptions = options.map((option, index) => (
      <option key={index} value={option[valueField]}>
        {option[labelField]}
      </option>
    ));

    return (
      <div className={`${styles.select} ${className}`}>
        <select
          id={id}
          className="form-control"
          value={this.state.selected}
          onChange={this.handleChange.bind(this)}
          disabled={disabled}
        >
          {selectOptions}
        </select>
      </div>
    );
  }
}
