import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Loader from 'components/Loader/Loader';
import { loadExternalScript } from '../../helpers/HelperFunctions';

export default class BraintreeDropin extends Component {
  static propTypes = {
    onPaymentMethodReceived: PropTypes.func,
    paymentToken: PropTypes.string,
    submitClass: PropTypes.string,
    submitId: PropTypes.string,
    submitText: PropTypes.string,
    submitLoadingText: PropTypes.string,
    validate: PropTypes.bool,
  };

  static defaultProps = {
    submitClass: 'btn btn-success btn-lg',
    submitId: 'pay-with-braintree-button',
    submitText: 'Make payment',
    submitLoadingText: 'Processing payment...',
    validate: false,
  };

  constructor() {
    super();

    this.initBraintree = this.initBraintree.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      dropinInstance: null,
      loadingBraintree: true,
      loadingError: false,
      loadingSubmit: false,
      disableInput: true,
    };
  }

  componentDidMount() {
    if (typeof braintree === 'undefined') {
      // loadExternalScript('https://js.braintreegateway.com/web/dropin/1.6.1/js/dropin.min.js', [], this.initBraintree);
      loadExternalScript(
        'https://js.braintreegateway.com/web/dropin/1.20.1/js/dropin.min.js',
        [],
        this.initBraintree
      );
    } else {
      this.initBraintree();
    }
  }

  componentWillUnmount() {
    if (this.state.dropinInstance) {
      this.state.dropinInstance.teardown();
    }
  }

  static initInterval = null;

  initBraintree() {
    this.initInterval = setInterval(() => {
      if (this.props.paymentToken) {
        clearInterval(this.initInterval);

        braintree.dropin.create(
          {
            authorization: this.props.paymentToken,
            container: '#dropin-container',
            card: {
              cardholderName: {
                required: true,
              },
            },
            translations: this.props.validate
              ? {
                  payingWith: '',
                  chooseAnotherWayToPay: 'Choose another way to validate',
                  chooseAWayToPay: '',
                  otherWaysToPay: 'Other ways to validate',
                  payWithCard: 'Validate with card',
                  Card: 'Verify with Card',
                  PayPal: 'Verify with PayPal',
                }
              : {},
            paypal: {
              flow: 'vault',
              buttonStyle: {
                size: 'medium',
                color: 'blue',
                label: this.props.validate ? 'checkout' : 'pay',
              },
            },
          },
          function (err, dropinInstance) {
            if (err) {
              console.log(err);
              // Handle any errors that might've occurred when creating Drop-in
              this.setState({
                loadingError: true,
                loadingBraintree: false,
              });
              return;
            }

            // isPaymentMethodRequestable returns true if the generated the client token
            // with a customer ID and there is a saved payment method
            // available to tokenize with that customer.
            this.setState({
              dropinInstance,
              loadingBraintree: false,
              disableInput: !dropinInstance.isPaymentMethodRequestable(),
            });

            dropinInstance.on(
              'paymentMethodRequestable',
              function () {
                this.setState({
                  disableInput: false,
                });
              }.bind(this)
            );

            dropinInstance.on(
              'noPaymentMethodRequestable',
              function () {
                this.setState({
                  disableInput: true,
                });
              }.bind(this)
            );
          }.bind(this)
        );
      }
    }, 1000);
  }

  handleSubmit() {
    this.setState({ loadingSubmit: true });

    this.state.dropinInstance.requestPaymentMethod(
      function (err, payload) {
        if (err) {
          // Handle errors in requesting payment method
          this.setState({ loadingSubmit: false });
        } else {
          // Send payload.nonce to your server
          this.props.onPaymentMethodReceived(payload);
        }
      }.bind(this)
    );
  }

  render() {
    return (
      <div>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          .braintree-option__card {
            display: none !important;
          }
          .braintree-option__paypal {
            border-top-width: 1px !important;
            border-radius: 4px !important;
          }
        `,
          }}
        />
        {this.state.loadingBraintree && (
          <div className="text-center mt++ mb++">
            Loading payment options...
            <Loader loading className="mt+ " />
          </div>
        )}
        {this.state.loadingError && (
          <div className="text-center mt++ mb++">
            Sorry, there was an error establishing a secure connection. Please
            try again later...
          </div>
        )}
        <div id="dropin-container" />
        {!this.state.loadingBraintree && !this.state.loadingError && (
          <input
            className={this.props.submitClass}
            type="submit"
            id={this.props.submitId}
            onClick={this.handleSubmit}
            disabled={this.state.loadingSubmit || this.state.disableInput}
            style={{ display: this.state.disableInput ? 'none' : 'block' }}
            value={
              this.state.loadingSubmit && this.props.submitLoadingText
                ? this.props.submitLoadingText
                : this.props.submitText
            }
          />
        )}
      </div>
    );
  }
}
