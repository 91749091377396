import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import SvgIcon from 'components/SvgIcon/SvgIcon';

import { getTextColour } from 'helpers/HelperFunctions';
import styles from './PortfolioAdverts.module.scss';
import LazyLoad from 'components/LazyLoad/LazyLoad';
import config from '../../../../../../config';

const getCompanyLogo = (logo = '') => {
  return `${config.partnerImages}/${logo}`;
};

const PortfolioAd = ({ advertData, onNextAdvert, onPrevAdvert }) => {
  const background = advertData?.background ?? '#ffffff';
  const hasWhiteText = getTextColour(background) === '#ffffff';

  const loadImage = () => {
    const lazyImage = (
      <LazyLoad className={styles.lazyLoadFlex}>
        <img
          src={getCompanyLogo(advertData.logo_image)}
          className={styles.partnerLogo}
        />
      </LazyLoad>
    );

    return (
      <Fragment>
        {advertData?.includeName ? (
          <Fragment>
            {lazyImage}
            <div className={styles.companyName}>{advertData.partner_name}</div>
          </Fragment>
        ) : (
          <div className={styles.partnerLogoContainer}>{lazyImage}</div>
        )}
      </Fragment>
    );
  };

  return (
    <div
      className={`${styles.advertContainer} ${hasWhiteText && `text-white`}`}
    >
      <div className={styles.advertHeader}>
        <div className={styles.prevAdvert} onClick={onPrevAdvert}>
          <SvgIcon icon="chevron-left" size="24" />
        </div>
        <a
          href={advertData.link_destination}
          className={`${styles.companyLogo} no-decoration`}
          rel="nofollow noreferrer"
          target="_blank"
        >
          {loadImage()}
        </a>
        <div className={styles.nextAdvert} onClick={onNextAdvert}>
          <SvgIcon icon="chevron-right" size="24" />
        </div>
      </div>

      <div className={styles.advertDescription}>
        <p>{advertData.description}</p>
        <a
          href={advertData.link_destination}
          rel="nofollow noreferrer"
          target="_blank"
          className={styles.advertCTA}
        >
          {advertData.link_text}{' '}
          <SvgIcon
            className={styles.CTAChevron}
            icon="chevron-right"
            size="12"
          />
        </a>
      </div>
    </div>
  );
};

PortfolioAd.defaultProps = {
  advertData: {},
  onNextAdvert: () => {},
  onPrevAdvert: () => {},
};

PortfolioAd.propTypes = {
  advertData: PropTypes.object,
  onNextAdvert: PropTypes.func,
  onPrevAdvert: PropTypes.func,
};

export default PortfolioAd;
