import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SelectSelectize from './SelectSelectize';

export default class SectorsSelector extends Component {
  static propTypes = {
    existing: PropTypes.array,
    onSelect: PropTypes.func,
    multi: PropTypes.bool,
  };

  static defaultProps = {
    existing: [],
    multi: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedSectors: props.existing.length
        ? props.existing.map((sector) => ({
            label: sector.name,
            value: sector.name,
          }))
        : [],
    };
  }

  componentDidMount() {
    const { selectedSectors } = this.state;
    const { onSelect } = this.props;
    if (selectedSectors.length) {
      if (onSelect) {
        onSelect(selectedSectors);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.selectedSectors !== this.state.selectedSectors &&
      this.props.onSelect
    ) {
      this.props.onSelect(this.state.selectedSectors);
    }
  }

  static sectors = [
    'Agriculture & Mining',
    'Computers & Electronics',
    'Consumer Goods',
    'Education',
    'Energy & Utilities',
    'Financial Services',
    'Gaming',
    'Government',
    'Healthcare',
    'Life Sciences',
    'Manufacturing',
    'Media & Entertainment',
    'Non-Profit Organization',
    'Professional Services',
    'Real Estate & Construction',
    'Retail',
    'Software & Internet',
    'Telecommunications',
    'Transportation & Logistics',
    'Travel & Hospitality',
    'Wholesale & Distribution',
    'Other',
  ];

  render() {
    const { multi, ...rest } = this.props;

    const { selectedSectors } = this.state;

    return (
      <SelectSelectize
        {...rest}
        isMulti={multi}
        hideResetButton
        options={SectorsSelector.sectors.map((sectorOption) => ({
          label: sectorOption,
          value: sectorOption,
        }))}
        values={selectedSectors}
        onValuesChange={(selected) => {
          this.setState({ selectedSectors: selected });
        }}
        placeholder="Eg: Media & Entertainment"
        id={'selectors-SectorsSelector-jobSectorSearch'}
      />
    );
  }
}
