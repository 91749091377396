import PropTypes from 'prop-types';
import React from 'react';
import LinkOrAnchor from './LinkOrAnchor';

const LinkOrSpan = ({ active, children, linkProps, spanProps }) =>
  active ? (
    <LinkOrAnchor isLink={!linkProps.to?.includes('/find/')} {...linkProps}>
      {children}
    </LinkOrAnchor>
  ) : (
    <span {...spanProps}>{children}</span>
  );

LinkOrSpan.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  linkProps: PropTypes.object,
  spanProps: PropTypes.object,
};

export default LinkOrSpan;
