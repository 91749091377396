export default {
  freelancerToolkit: [
    {
      partner_name: `Freelancer Toolkit`,
      description: `A Twine curated collection of essential tools and services for creative and digital freelancers.`,
      link_destination: `https://link.twine.fm/PortfolioFreelancerToolkit`,
      link_text: `See more`,
      logo_image: `freelancer-toolkit-logo.webp`,
      includeName: true,
      background: `#343260`,
    },
  ],
};

// eslint-disable-next-line no-unused-vars
const advertObjectTemplate = [
  {
    name: ``,
    description: ``,
    link: ``,
    linkCta: ``,
    logo: ``,
    includeName: false,
    background: `#ffffff`,
  },
];
