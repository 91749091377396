import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import Icon from 'components/Icon/Icon';
import { loadExternalScript } from 'helpers/HelperFunctions';

import './LocationInput.module.scss';
import styles from './LocationInput.module.scss';

class LocationInput extends React.Component {
  inputRef = createRef();

  static propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    containerClass: PropTypes.string,
    showResetIcon: PropTypes.bool,
    onChangePlace: PropTypes.func,
    onBlur: PropTypes.func,
    clearFilter: PropTypes.func,
    id: PropTypes.string.isRequired,
    googleAPIType: PropTypes.string,
    shouldClearOnBlur: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    onBlur: () => {},
    onChangePlace: () => {},
    googleAPIType: 'cities',
    placeholder: 'Enter Location',
  };

  state = {
    mapsAutocomplete: null,
  };

  componentDidMount() {
    if (__CLIENT__) {
      loadExternalScript(
        'https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyBrIYfrSMzHXruZymMRys-5dfrDFuJicis',
        [{ name: 'defer', value: true }],
        this.attachMapsWidgetToInput
      );
    }
  }

  attachMapsWidgetToInput = () => {
    if (typeof google !== 'undefined') {
      this.setState(
        {
          mapsAutocomplete: new google.maps.places.Autocomplete(
            document.getElementById(this.props.id),
            { types: [`(${this.props.googleAPIType})`] }
          ),
        },
        () =>
          this.state.mapsAutocomplete.addListener(
            'place_changed',
            this.handleChangeLocation
          )
      );
    }
  };

  handleChangeLocation = () => {
    const place = this.state.mapsAutocomplete.getPlace();

    const address = {};
    address.city = '';
    address.country = '';
    address.country_code = '';

    place.address_components.forEach((c) => {
      if (
        c.types[0] === 'locality' ||
        c.types[1] === 'locality' ||
        c.types[2] === 'locality'
      ) {
        address.city = c.long_name;
      }
      if (c.types[0] === 'country') {
        address.country = c.long_name;
        address.country_code = c.short_name;
      }
    });

    this.props.onChangePlace({
      lat: place.geometry.location.lat(),
      lon: place.geometry.location.lng(),
      city: address.city,
      country: address.country,
      country_code: address.country_code,
      text: this.inputRef.current.value,
      method: 'html5',
      countryOnly: address.city === '' ? 'countryOnly' : 'specificAddress',
    });
  };

  handleBlur = () => {
    const { onBlur, shouldClearOnBlur } = this.props;

    if (shouldClearOnBlur) {
      this.inputRef.current.value = '';
    }
    onBlur();
  };

  render() {
    const {
      className,
      containerClass,
      placeholder,
      showResetIcon,
      clearFilter,
      onBlur,
      onChangePlace,
      shouldClearOnBlur,
      googleAPIType,
      ...rest
    } = this.props;

    const hasTextChanged =
      __CLIENT__ && this.inputRef.current && this.inputRef.current.value !== '';

    return (
      <div
        className={containerClass || styles.locationInputDefaultContainer}
        style={{ position: showResetIcon && 'relative' }}
      >
        <input
          {...rest}
          ref={this.inputRef}
          type="text"
          onBlur={this.handleBlur}
          className={`form-control ${className}`}
          placeholder={placeholder}
        />

        {showResetIcon && (
          <div
            className={styles.crossIconContainer}
            style={{
              opacity: hasTextChanged ? 1 : 0,
            }}
          >
            <Icon
              color="#aaa"
              icon="&#xe60d;"
              color="#363c6e"
              style={{ verticalAlign: 'sub' }}
              onClick={clearFilter}
            />
          </div>
        )}
      </div>
    );
  }
}

export default LocationInput;
