import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import BraintreeDropin from 'components/Shared/BraintreeDropin';

const AddPaymentForPitch = ({
  onCancel,
  onPaymentMethodReceived,
  paymentToken,
}) => {
  return (
    <div>
      <Row>
        <Col xs={12}>
          <p
            style={{
              textAlign: 'center',
              fontSize: '200%',
              fontFamily: 'Lato',
              fontWeight: '700',
            }}
          >
            <strong>Add payment method</strong>
          </p>
          <p style={{ textAlign: 'center', fontFamily: 'Lato' }}>
            There's no charge. It's only needed for validation
          </p>
          <p
            style={{
              textAlign: 'center',
              fontFamily: 'Lato',
              maxWidth: '370px',
              margin: '0 auto',
            }}
          >
            You will be charged when the project is complete and you're covered
            by our <strong>money back guarantee</strong>.
          </p>
          <hr />
          <p style={{ textAlign: 'center', fontFamily: 'Lato' }}>
            Total due now: <strong>$0.00</strong>
          </p>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <BraintreeDropin
            onPaymentMethodReceived={onPaymentMethodReceived}
            paymentToken={paymentToken}
            submitClass={'btn btn-success btn-lg mt right'}
            submitText="Done"
            validate
          />
          <Button className="mt" bsSize="lg" bsStyle="info" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
      </Row>
    </div>
  );
};

AddPaymentForPitch.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onPaymentMethodReceived: PropTypes.func.isRequired,
  paymentToken: PropTypes.string,
  pitch: PropTypes.object,
};

export default AddPaymentForPitch;
