import React from 'react';
import Icon from 'components/Icon/Icon';
import PropTypes from 'prop-types';

const ReportUser = ({
  onChangeReportComments,
  onChangeReportReason,
  onReport,
  reportComments,
  reportReason,
  user,
}) => {
  const maxCommentsLength = 1000;

  return (
    <div className="p++">
      <h3 className="lato-bold text-center mb">Report {user?.displayname}</h3>
      <div className="form-group">
        <label htmlFor="report-reason">Select a reason for your report</label>
        <select
          className="form-control"
          id="report-reason"
          value={reportReason}
          onChange={(event) => onChangeReportReason(event.target.value)}
        >
          <optgroup label="Select a reason for your report">
            <option value="Inappropriate content uploaded">
              Inappropriate content uploaded
            </option>
            <option value="Hack incidents">Hack incidents</option>
            <option value="Offended other users">Offended other users</option>
            <option value="Inappropriate profile picture">
              Inappropriate profile picture / cover art
            </option>
            <option value="Spam">Spam</option>
          </optgroup>
        </select>
      </div>
      <div
        className={
          'form-group no-margin' +
          (maxCommentsLength - reportComments.length < 200
            ? ' has-warning'
            : '') +
          (maxCommentsLength - reportComments.length < 100 ? ' has-error' : '')
        }
      >
        <label htmlFor="report-comments">Any additional comments?</label>
        <textarea
          className="form-control"
          id="report-comments"
          maxLength={maxCommentsLength}
          onChange={(event) => onChangeReportComments(event.target.value)}
          placeholder="Please enter as much information as possible, this will help us deal with your report as efficiently as possible."
          rows="4"
          value={reportComments}
        />
        <div
          className="form-control-static text-right no-padding"
          style={{ fontSize: '0.8em' }}
        >
          Characters left: {maxCommentsLength - reportComments.length}
        </div>
      </div>
      <div className="text-center">
        <button
          className="btn btn-primary"
          onClick={() =>
            onReport(
              user,
              document.getElementById('report-reason').value,
              document.getElementById('report-comments').value
            )
          }
        >
          <Icon icon="&#xe618;" className="mr-" />
          Submit report
        </button>
      </div>
    </div>
  );
};

ReportUser.propTypes = {
  user: PropTypes.object.isRequired,
  reportReason: PropTypes.string.isRequired,
  reportComments: PropTypes.string.isRequired,
  onChangeReportComments: PropTypes.func.isRequired,
  onChangeReportReason: PropTypes.func.isRequired,
  onReport: PropTypes.func.isRequired,
};

export default ReportUser;
